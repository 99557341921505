<template>
  <List />
</template>

<script>
  import List from '@/views/operate/compenents/list'
  export default {
    components: {
      List,
    },
  }
</script>

<style></style>
